import type { Editor } from 'slate';

export default (editor: Editor): Editor => {
  const { isInline } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => {
    return element.type === 'image-reference' ? true : isInline(element);
  };

  return editor;
};
