import { Editor, Transforms } from 'slate';
import {
  CreateImageNodeArgs,
  emptyText,
  ImageElement,
  isImageUrl,
} from '../AddImage/AddImageButton';

const createImageNode = ({
  alt = 'Image',
  description,
  src,
}: CreateImageNodeArgs): ImageElement[] => [
  {
    alt,
    description,
    src,
    type: 'image',
    children: [emptyText],
  },
];

const withImages = (editor: Editor): Editor => {
  const { isVoid, insertData } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) =>
    element.type === 'image' ? true : isVoid(element);

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    const image = createImageNode({
      src: text,
      alt: text,
    });

    if (isImageUrl(text)) {
      Transforms.insertNodes(editor, image);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withImages;
