import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import InlineSVG from 'react-inlinesvg';
import styled from 'styled-components';
import ArrowSvg from '../../assets/arrow.svg';
import useInterval from '../../utils/useInterval';
import Tab from './Tab';

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: solid 1px ${({ theme }): string => theme.colors.black05Alpha};
  padding-right: 0px;
  background-color: ${({ theme }): string => theme.colors.porcelain};
`;

const ScrollButtonWrapper = styled.div`
  position: relative;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const LeftScrollArrow = styled(InlineSVG)`
  transform: rotate(180deg);
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 3;
`;

const LeftFade = styled.div`
  position: absolute;
  height: 40px;
  border-bottom: solid 1px
    linear-gradient(
      90deg,
      ${({ theme }): string => theme.colors.black05Alpha} 80%,
      rgba(36, 45, 65, 0) 99%
    );
  background: linear-gradient(
    90deg,
    ${({ theme }): string => theme.colors.porcelain} 80%,
    rgba(251, 252, 253, 0) 99%
  );
  width: 30px;
  left: 0px;
  z-index: 2;
  cursor: pointer;
  &:hover {
    ${LeftScrollArrow} {
      transition: fill 0.2s;
      fill: ${({ theme }): string => theme.colors.brandBlue};
    }
  }
  &:active {
    ${LeftScrollArrow} {
      fill: ${({ theme }): string => theme.colors.brandBlue80Alpha};
    }
  }
`;

const RightScrollArrow = styled(InlineSVG)`
  position: absolute;
  right: 15px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 0px;
  z-index: 3;
`;

const RightFade = styled.div`
  position: absolute;
  height: 40px;
  border-bottom: solid 1px
    linear-gradient(
      90deg,
      rgba(36, 45, 65, 0) 10%,
      ${({ theme }): string => theme.colors.black05Alpha} 40%
    );
  background: linear-gradient(
    90deg,
    rgba(251, 252, 253, 0) 10%,
    ${({ theme }): string => theme.colors.porcelain} 40%
  );
  cursor: pointer;
  width: 30px;
  padding-left: 20px;
  right: 0px;
  overflow-y: visible;
  z-index: 2;
  &:hover {
    ${RightScrollArrow} {
      transition: fill 0.2s;
      fill: ${({ theme }): string => theme.colors.brandBlue};
    }
  }
  &:active {
    ${RightScrollArrow} {
      fill: ${({ theme }): string => theme.colors.brandBlue80Alpha};
    }
  }
`;

const TabControlsWrapper = styled.div`
  z-index: 1;
  width: fit-content;
  padding-top: 8px;
  margin-left: 10px;
  padding-right: 10px;
`;

export type MenuItem = {
  label: string;
  value: string;
};

export type ContextTabsProps = {
  items: MenuItem[];
  selectedItem?: MenuItem;
  onSelect: (item: MenuItem) => void;
  tabControls?: ReactNode;
};

export const ContextTabs = ({
  items: itemsProp,
  selectedItem: selectedItemProp,
  onSelect,
  tabControls,
}: ContextTabsProps): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<MenuItem>(
    selectedItemProp || itemsProp[0]
  );
  const [rightScrollButtonIsVisible, setRightScrollButtonIsVisible] =
    useState<boolean>(false);
  const [leftScrollButtonIsVisible, setLeftScrollButtonIsVisible] =
    useState<boolean>(false);
  const [items, setItems] = useState<MenuItem[]>(itemsProp);

  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // on page load just checking if the number of tabs exceeds the width
    if (
      tabsRef.current &&
      tabsRef.current.offsetWidth < tabsRef.current.scrollWidth
    ) {
      setRightScrollButtonIsVisible(true);
    }
  }, [tabsRef]);

  const checkIfScrollButtonsShouldBeVisible = useCallback(() => {
    if (tabsRef.current) {
      setRightScrollButtonIsVisible(
        tabsRef.current.offsetWidth < tabsRef.current.scrollWidth
      );
      setLeftScrollButtonIsVisible(tabsRef.current.scrollLeft > 0);
    }
  }, []);

  useEffect(() => {
    if (selectedItemProp) {
      setSelectedItem(selectedItemProp);
    }
  }, [selectedItemProp]);

  useEffect(() => {
    setItems(itemsProp);
  }, [checkIfScrollButtonsShouldBeVisible, itemsProp]);

  useEffect(() => {
    checkIfScrollButtonsShouldBeVisible();
  }, [checkIfScrollButtonsShouldBeVisible, items]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      checkIfScrollButtonsShouldBeVisible();
    });
  });

  const [leftMouseIsDown, setLeftMouseIsDown] = useState<boolean>(false);
  const [rightMouseIsDown, setRightMouseIsDown] = useState<boolean>(false);

  useEffect(() => {
    // this handles both when the button disappears
    // and also when the user drags their mouse away from the button
    window.addEventListener(
      'mouseup',
      () => {
        if (rightMouseIsDown) {
          setRightMouseIsDown(false);
        }
        if (leftMouseIsDown) {
          setLeftMouseIsDown(false);
        }
      },
      false
    );
  });

  const handleOnMouseDown = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: leftMouseIsDown ? -40 : 40,
        behavior: 'smooth',
      });
    }
  };

  useInterval(handleOnMouseDown, rightMouseIsDown ? 80 : null);
  useInterval(handleOnMouseDown, leftMouseIsDown ? 80 : null);

  const handleOnScroll = () => {
    if (tabsRef.current) {
      const scrollPosition = tabsRef.current.scrollLeft;
      const shouldShowLeft = scrollPosition > 0;
      if (shouldShowLeft !== leftScrollButtonIsVisible) {
        setLeftScrollButtonIsVisible(shouldShowLeft);
      }
      const shouldShowRight =
        tabsRef.current.offsetWidth + scrollPosition <
        tabsRef.current.scrollWidth;
      if (shouldShowRight !== rightScrollButtonIsVisible) {
        setRightScrollButtonIsVisible(shouldShowRight);
      }
    }
  };

  return (
    <ComponentWrapper>
      {leftScrollButtonIsVisible && (
        <ScrollButtonWrapper>
          <LeftFade
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setLeftMouseIsDown(true);
            }}
            onMouseUp={() => {
              setLeftMouseIsDown(false);
            }}
          >
            <LeftScrollArrow src={ArrowSvg} />
          </LeftFade>
        </ScrollButtonWrapper>
      )}
      <TabsWrapper
        data-testid="TabsWrapper"
        ref={tabsRef}
        onScroll={handleOnScroll}
      >
        {items.map((item, index) => {
          return (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={`Tab-${index}`}
              isSelected={selectedItem === item}
              onClick={() => {
                setSelectedItem(item);
                onSelect(item);
              }}
              item={item}
              parentRef={tabsRef}
            />
          );
        })}
      </TabsWrapper>
      {rightScrollButtonIsVisible && (
        <ScrollButtonWrapper>
          <RightFade
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setRightMouseIsDown(true);
            }}
            onMouseUp={() => {
              setRightMouseIsDown(false);
            }}
          >
            <RightScrollArrow src={ArrowSvg} />
          </RightFade>
        </ScrollButtonWrapper>
      )}
      {tabControls && (
        <TabControlsWrapper data-testid="TabControlsWrapper">
          {tabControls}
        </TabControlsWrapper>
      )}
    </ComponentWrapper>
  );
};

export default ContextTabs;
