import React from 'react';
import type { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import type { ImageMetadata } from './RichTextEditor';
import Image from './AddImage/Image';
import ImageReference from './AddImage/ImageReference';
import AlertBox from './AlertBox';

const RichTextTable = styled.table`
  border-radius: 2px;
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  border-collapse: collapse;
`;

const RichTextCell = styled.td`
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  width: 135px;
  height: 36px;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
`;

const Indentation = styled.div`
  padding-left: 25px;
`;

interface ImagesMetadata {
  images: ImageMetadata[];
}

const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps & ImagesMetadata): JSX.Element => {
  switch (element.type) {
    case 'caution-box':
      return (
        <AlertBox isCautionBox attributes={attributes}>
          {children}
        </AlertBox>
      );
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'table':
      return (
        <RichTextTable {...attributes}>
          <tbody>{children}</tbody>
        </RichTextTable>
      );
    case 'table-row':
      return <tr {...attributes}>{children}</tr>;
    case 'table-cell':
      return <RichTextCell {...attributes}>{children}</RichTextCell>;
    case 'warning-box':
      return <AlertBox attributes={attributes}>{children}</AlertBox>;
    case 'indentation':
      return <Indentation {...attributes}>{children}</Indentation>;
    case 'image':
      return (
        <Image attributes={attributes} element={element}>
          {children}
        </Image>
      );
    case 'image-reference':
      return <ImageReference>{children}</ImageReference>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
