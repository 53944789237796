import React, { useEffect, useState } from 'react';
import styled, {
  keyframes,
  css,
  FlattenSimpleInterpolation,
  FlattenInterpolation,
  ThemedStyledProps,
  DefaultTheme,
} from 'styled-components';
import closeIcon from '../../assets/cancel.svg';

export interface DrawerProps {
  visible: boolean;
  handleClose: () => void;
  width?: number;
  data?: boolean;
}

const slideOut = keyframes`
  from {
    right: -872px;
  }
  to {
    right: 0;
  }
`;

const slideIn = keyframes`
  from {
    right: 0;
  }
  to {
    right: -872px;
  }
`;

const fadeBg = keyframes`
  from {
    background-color: rgba(50, 50, 50, 0);
  }
  to {
    background-color: rgba(0, 30, 61, 0.35);
  }
`;

const hideBackground = keyframes`
from {
  background-color: rgba(0, 30, 61, 0.35);
}
to {
  background-color: rgba(50, 50, 50, 0);
}
`;

const BGFadeOut = css`
  animation: ${fadeBg} 0.3s;
  left: 0;
`;

const BGHidden = css`
  animation: ${hideBackground} 0.3s;
  left: 100%;
`;

const DrawerSlideOut = css<{
  width: number;
}>`
  max-height: calc(100vh - 24px);
  min-height: 50vh;
  height: auto;
  width: ${({ width }): string => (width ? `${width}px` : '776px')};
  right: 0;
  background-color: #fcfdff;
  border-radius: 5px 0 0 5px;
  animation: ${slideOut} 0.3s;
  @media (max-width: 770px) {
    width: 768px;
  }
  @media (max-width: 450px) {
    width: 100vw;
  }
`;

const DrawerSlideIn = css<{
  width: number;
}>`
  max-height: calc(100vh - 24px);
  min-height: 50vh;
  height: auto;
  width: ${({ width }): string => (width ? `${width}px` : '776px')};
  right: -872px;
  background-color: #fcfdff;
  border-radius: 5px 0 0 5px;
  animation: ${slideIn} 0.3s;
  @media (max-width: 770px) {
    width: 768px;
  }
  @media (max-width: 450px) {
    width: 100vw;
  }
`;

const DrawerWrapper = styled.div<{
  visible: boolean;
  width: number;
  data: boolean | undefined;
}>`
  height: calc(100vh - 24px);
  top: 12px;
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  display: ${({ data }): string => (data ? 'inline-block' : 'none')};
  ${({
    visible,
    data,
  }): FlattenInterpolation<
    ThemedStyledProps<{ width: number }, DefaultTheme>
  > => (data && !visible ? DrawerSlideIn : DrawerSlideOut)}
`;

const DrawerBackground = styled.div<{
  visible: boolean;
  data: boolean | undefined;
}>`
  background-color: ${({ visible }): string =>
    visible ? 'rgba(0, 30, 61, 0.35)' : 'rgba(50, 50, 50, 0)'};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: 300;
  display: ${({ data }): string => (data ? 'inline-block' : 'none')};
  ${({ visible, data }): FlattenSimpleInterpolation =>
    data && !visible ? BGHidden : BGFadeOut}
`;

const DrawerInnerWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: scroll;
`;

const DrawerCloseButton = styled.button`
  position: absolute;
  top: 32px;
  right: 48px;
  z-index: 201;
  padding: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  @media (max-width: 450px) {
    top: 24px;
    right: 24px;
  }
`;

const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const ChildWrapper = styled.div`
  width: 100%;
  text-align: unset;
  height: 100%;
`;

export const Drawer: React.FC<DrawerProps> = ({
  visible,
  handleClose,
  children,
  width = 776,
  data,
}) => {
  const [localData, setLocalData] = useState(false);

  useEffect(() => {
    if (!data) {
      setTimeout(() => {
        setLocalData(false);
      }, 300);
    } else {
      setLocalData(true);
    }
  }, [data]);

  useEffect(() => {
    const div = document.getElementById('innerWrapper');
    if (div) div.scrollTop = 0;
  }, [children]);

  return (
    <DrawerBackground onClick={handleClose} data={localData} visible={visible}>
      <DrawerWrapper
        id="innerWrapper"
        visible={visible}
        width={width}
        data={localData}
      >
        <DrawerInnerWrapper>
          <DrawerCloseButton
            type="button"
            onClick={(e): void => {
              e.stopPropagation();
              handleClose();
            }}
            data-testid="TFDrawer--CloseButton"
          >
            <CloseIcon
              src={closeIcon}
              alt="close icon"
              id="drawerCloseButton"
            />
          </DrawerCloseButton>
          <ChildWrapper
            role="button"
            onClick={(e): void => e.stopPropagation()}
            onKeyDown={(): void | null => null}
            tabIndex={0}
          >
            {children}
          </ChildWrapper>
        </DrawerInnerWrapper>
      </DrawerWrapper>
    </DrawerBackground>
  );
};
