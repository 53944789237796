import React from 'react';
import { Element as CustomElement, Editor, Path, Transforms } from 'slate';
import { useSelected, useSlateStatic } from 'slate-react';
import { defaultValue } from '../../../utils/utils';
import { EditorImage, ImageWrapper } from './imageStyles';

interface ImageProps {
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true | undefined;
    'data-slate-void'?: true | undefined;
    dir?: 'rtl' | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any;
  };
  children: JSX.Element | JSX.Element[];
  element: CustomElement;
}

const Image = ({
  attributes,
  children,
  element,
}: ImageProps): React.ReactElement => {
  const editor = useSlateStatic();
  const isSelected = useSelected();
  const { selection } = editor;
  const { alt, description, src } = element;

  const handleImageKeyDown = (event: React.KeyboardEvent) => {
    const shouldDeleteImage =
      event.key === 'Backspace' || event.key === 'Delete';
    const shouldCreateNewNode =
      event.key === 'Enter' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowDown';

    if (isSelected && selection) {
      const [, parentPath] = Editor.parent(editor, selection?.focus?.path);

      if (shouldDeleteImage) {
        Transforms.removeNodes(editor, { at: parentPath });
      }

      if (shouldCreateNewNode) {
        Transforms.insertNodes(editor, defaultValue, {
          at: Path.next(parentPath),
          select: true,
        });
      }
    }
  };

  return (
    <ImageWrapper contentEditable={false} {...attributes}>
      <EditorImage
        tabIndex={0}
        src={src}
        alt={alt}
        isSelected={isSelected}
        onKeyDown={handleImageKeyDown}
      />
      {description && <div className="description">{description}</div>}
      {children}
    </ImageWrapper>
  );
};

export default Image;
