import React, { memo, useState } from 'react';
import type { Editor } from 'slate';
import { useSlate } from 'slate-react';
import NumberList from '../../assets/RichTeIcons/icon-numbered-list.svg';
import NumberListActive from '../../assets/RichTeIcons/icon-numbered-list-active.svg';
import BulletList from '../../assets/RichTeIcons/icon-bulleted-list.svg';
import BulletListActive from '../../assets/RichTeIcons/icon-bulleted-list-active.svg';
import { ensureSelection, isBlockActive, toggleBlock } from './utils';
import { ToolButtonWrapper, ToolButton } from './ToolBarStyles';
import ToolTip from './ToolTip';

type BlockButtonProps = {
  activeIcon: string;
  icon: string;
  tooltip: string;
  isActive: boolean;
  'data-testid': string;
  onToggle: () => void;
};

const BlockButton = memo(
  ({
    icon,
    activeIcon,
    tooltip,
    isActive,
    onToggle,
    'data-testid': dataTestId,
  }: BlockButtonProps) => {
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

    return (
      <ToolButtonWrapper data-testid={dataTestId}>
        <ToolTip text={tooltip} isVisible={tooltipIsVisible}>
          <ToolButton
            onClick={onToggle}
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
          >
            <img src={isActive ? activeIcon : icon} alt="mark button" />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
    );
  }
);

export default (): JSX.Element => {
  const editor: Editor = useSlate();

  return (
    <>
      <BlockButton
        tooltip="Numbered List"
        icon={NumberList}
        activeIcon={NumberListActive}
        data-testid="NumberListButton"
        isActive={isBlockActive(editor, 'numbered-list')}
        onToggle={() => {
          ensureSelection(editor);
          toggleBlock(editor, 'numbered-list');
        }}
      />
      <BlockButton
        tooltip="Bulleted List"
        icon={BulletList}
        activeIcon={BulletListActive}
        data-testid="BulletListButton"
        isActive={isBlockActive(editor, 'bulleted-list')}
        onToggle={() => {
          ensureSelection(editor);
          toggleBlock(editor, 'bulleted-list');
        }}
      />
    </>
  );
};
