import React, { memo, useCallback, useState } from 'react';
import { useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import {
  ActiveColorIcon,
  ToolButton,
  ToolButtonWrapper,
} from '../ToolBarStyles';
import ToolTip from '../ToolTip';

const ColorMenuOuterWrapper = styled.div`
  position: relative;
`;

export const ColorMenus = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  left: -22px;
  width: 172px;
  height: 81px;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.black10Alpha};
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

type ColorPaletteItemProps = {
  color: string;
  isActive: boolean;
};

export const ColorPaletteItem = styled.button<ColorPaletteItemProps>`
  margin: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--white);
  position: relative;
  cursor: pointer;
  background-color: ${({ color }) => color};
  box-shadow: ${({ isActive, theme }) =>
    isActive ? ` 0 0 0 1px ${theme.colors.brandBlue}` : 'none;'};
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brandBlue10Alpha};
  }
`;

export const RemoveColor = styled.img`
  width: 16px;
  height: 16px;
  top: -2px;
  left: -2px;
  position: absolute;
`;

export const TextColorPopupTopRow = styled.div`
  display: flex;
  height: 40px;
  padding: 5px;
`;

export const TextColorPopupBottomRow = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const TextColorPicker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColorPaletteLabel = styled.p`
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2px;
`;

interface ColorMenuProps {
  children: JSX.Element | JSX.Element[];
  showMenu: boolean;
  menuToggle(fn: (menuState: boolean) => boolean): void;
  toggleOtherMenus: {
    siblingColourMenu: (arg0: boolean) => void;
    tableMenu: (arg0: boolean) => void;
  };
  otherMenuStates: boolean[];
  icon: string;
  label: string;
  type: 'textColor' | 'highlightColor';
}

export default memo(
  ({
    children,
    showMenu,
    menuToggle,
    icon,
    label,
    type,
    toggleOtherMenus: { siblingColourMenu, tableMenu },
    otherMenuStates,
  }: ColorMenuProps) => {
    const editor: Editor = useSlate();
    const marks = Editor.marks(editor);
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

    const handleMenuToggle = useCallback(() => {
      if (otherMenuStates.includes(true)) {
        siblingColourMenu(false);
        tableMenu(false);
      }
      if (!editor.selection) {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
      }
      menuToggle((menuState) => !menuState);
    }, [otherMenuStates, editor, menuToggle, siblingColourMenu, tableMenu]);

    const defaultColor =
      type === 'highlightColor' ? 'var(--black-10)' : 'black';

    return (
      <>
        <ToolButtonWrapper>
          <ToolTip
            text={`${type.replace(/([A-Z])/g, ' $1').trim()}`}
            isVisible={tooltipIsVisible && !showMenu}
          >
            <ToolButton
              type="button"
              onClick={handleMenuToggle}
              onMouseEnter={() => {
                setTooltipIsVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipIsVisible(false);
              }}
            >
              <ActiveColorIcon
                src={icon}
                color={
                  marks && marks[type] ? (marks[type] as string) : defaultColor
                }
              />
            </ToolButton>
          </ToolTip>
        </ToolButtonWrapper>
        {showMenu && (
          <OutsideClickHandler
            onOutsideClick={() => {
              handleMenuToggle();
            }}
          >
            <ColorMenuOuterWrapper>
              <ColorMenus>
                <TextColorPopupTopRow>
                  <TextColorPicker>
                    <img src={icon} alt="icon" />
                  </TextColorPicker>
                  <ColorPaletteLabel>{label}</ColorPaletteLabel>
                </TextColorPopupTopRow>
                <TextColorPopupBottomRow onClick={handleMenuToggle}>
                  {children}
                </TextColorPopupBottomRow>
              </ColorMenus>
            </ColorMenuOuterWrapper>
          </OutsideClickHandler>
        )}
      </>
    );
  }
);
