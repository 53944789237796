import styled from 'styled-components';
import InlineSVG from 'react-inlinesvg';

export const ToolButtonWrapper = styled.div`
  position: relative;
`;

export const ActiveColorIcon = styled(InlineSVG)<{ color: string }>`
  rect {
    fill: ${({ color }) => color};
    stroke: ${({ color }) => color};
  }
`;

export const ToolButtonIcon = styled(InlineSVG)<{ $isActive: boolean }>`
  width: 26px;
  height: 26px;
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--brand-blue-5)' : 'transparent;'};
  polygon {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
  path {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
  rect {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
`;

export const SanitiseButtonIcon = styled(InlineSVG)<{ $isActive: boolean }>`
  width: 26px;
  height: 26px;
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--brand-blue-5)' : 'transparent;'};
  polygon {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
  path {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
`;

export const ToolButton = styled.button<{ disabled?: boolean }>`
  margin: 2px;
  border: none;
  background: transparent;
  width: 26px;
  height: 26px;
  padding: 0;

  .add-image {
    width: 26px;
    height: auto;
  }
  &:hover {
    cursor: ${({ disabled }) => (!disabled ? 'pointer;' : 'default;')};
    img,
    ${ToolButtonIcon}, ${ActiveColorIcon}, ${SanitiseButtonIcon} {
      ${({ disabled }) =>
        !disabled ? 'background-color: var(--brand-blue-5);' : ''};
    }
  }
  &:focus {
    outline: none;
  }
`;

export const toolBarColors: Record<string, Record<string, string>> = {
  textColor: {
    black: 'var(--rich-text-black)',
    blue: 'var(--rich-text-blue)',
    yellow: 'var(--rich-text-yellow)',
  },
  highlightColor: {
    pink: 'var(--rich-text-pink)',
    flYellow: 'var(--rich-text-fluorescent-yellow)',
    green: 'var(--rich-text-green)',
  },
};

export const HistoryButtonIcon = styled.img<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '20%' : '100%')};
`;
