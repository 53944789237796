import { motion } from 'framer-motion';
import InlineSvg from 'react-inlinesvg';
import styled, { Interpolation } from 'styled-components';

export const ButtonMenuWrapper = styled.div<{
  isRightAligned: boolean;
  $buttonWidth?: string;
  styleOverrides?: Interpolation<React.CSSProperties>;
}>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: ${({ isRightAligned }): string =>
    isRightAligned ? 'flex-end' : 'flex-start'};
  .button-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button-wrapper {
    display: flex;
    justify-content: ${({ isRightAligned }): string =>
      isRightAligned ? 'flex-end' : 'flex-start'};
    width: ${({ $buttonWidth }) => $buttonWidth || 'auto'};
    margin-bottom: 8px;
    margin-right: 10px;
  }
`;

export const ButtonDropdownIcon = styled.img<{ isOpen: boolean }>`
  height: auto;
  margin-left: 10px;
  transition: all 300ms;
  transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : 'none')};
  width: 6px;
`;

export const ComponentWrapper = styled(motion.div)<{
  $isBelow?: boolean;
}>`
  display: ${({ $isBelow }): string => ($isBelow ? 'block' : 'inline-block')};
  position: relative;
  z-index: 1;
`;

export const ItemChevron = styled(InlineSvg)<{
  isExpanded?: boolean;
  $hasWarningStyle?: boolean;
  $isRightAligned: boolean;
}>`
  width: 12px;
  height: 12px;
  position: absolute;
  right: ${({ $isRightAligned }): string =>
    $isRightAligned ? '15px' : '20px;'};
  top: 13px;
  fill: ${({ $hasWarningStyle }) =>
    $hasWarningStyle ? 'var(--red-90)' : 'var(--black)'};
`;

export const MenuItemWrapper = styled.div<{
  isRightAligned: boolean;
  isStringLabel: boolean;
  $hasWarningStyle?: boolean;
}>`
  align-items: center;
  background-color: white;
  border: none;
  color: ${({ $hasWarningStyle }) =>
    $hasWarningStyle ? 'var(--red-90)' : 'var(--black-80)'};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: ${({ isStringLabel }): string =>
    isStringLabel ? '10px 15px' : '0'};
  position: relative;
  text-align: left;
  &:hover {
    cursor: pointer;
    color: ${({ $hasWarningStyle }) =>
      $hasWarningStyle ? 'var(--red-90)' : 'var(--brand-blue)'};
    background-color: ${({ $hasWarningStyle, theme }): string =>
      $hasWarningStyle
        ? theme.colors.red5Alpha
        : theme.colors.brandBlue05Alpha};
  }
  &:hover ${ItemChevron} {
    fill: ${({ $hasWarningStyle }) =>
      $hasWarningStyle ? 'var(--red-90)' : 'var(--brand-blue)'};
    filter: brightness(1);
  }
  &:focus {
    box-shadow: ${({ $hasWarningStyle }) =>
      $hasWarningStyle
        ? `inset 0 0 0 1px ${(): string => 'var(--brand-blue)'}`
        : `inset 0 0 0 1px ${(): string => 'var(--red-90)'}`};
    background-color: ${({ theme }): string => theme.colors.salt};
    color: ${({ $hasWarningStyle }) =>
      $hasWarningStyle ? 'var(--red-90)' : 'var(--brand-blue)'};
  }
  &:active {
    background-color: ${({ $hasWarningStyle, theme }): string =>
      $hasWarningStyle
        ? theme.colors.red10Alpha
        : theme.colors.brandBlue10Alpha};
    color: ${({ $hasWarningStyle }): string =>
      $hasWarningStyle ? 'var(--red-90)' : 'var(--brand-blue)'};
  }
`;

export const MenuWrapper = styled.div<{
  $customMenuHeight?: string;
  isRightAligned: boolean;
}>`
  background: ${({ theme }): string => theme.colors.white};
  border: 1px solid ${({ theme }): string => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 0 10px 5px ${({ theme }): string => theme.colors.frostedSteel};
  font-size: 13px;
  ${({ isRightAligned }) =>
    isRightAligned ? 'margin-left: 10px;' : 'margin-right 10px;'};
  height: ${({ $customMenuHeight }) => $customMenuHeight || 'auto'};
  overflow: auto;
  width: 270px;
  span {
    width: 90%;
  }
`;

export const SubItemMenu = styled(MenuWrapper).attrs((props) => ({
  className: props.className || '',
}))`
  max-height: 300px;
  overflow: auto;
`;

export const SubItemsWrapper = styled.div<{ isRightAligned: boolean }>`
  position: absolute;
  ${({ isRightAligned }): string => {
    // TODO: July 28, 2022. more work to do here for positioning
    // if styling to resize subitems containers added through sx prop.
    return isRightAligned ? 'right: 10px;' : 'left: 280px;';
  }};
  top: -38px;
`;

export const SubMenuWrapper = styled(motion.div)`
  position: absolute;
  top: auto;
  z-index: 1;
`;
