import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { DropAnimation } from './DropAnimation';
import HoverAnimation from './HoverAnimation';
import { LoadingAnimation } from './LoadingAnimation';
import UnsupportedAnimation from './UnsupportedAnimation';

const Box = styled.div<{ fileIsUploading: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border: dashed 1px ${({ theme }): string => theme.colors.brandBlue};
  background: ${({ theme }): string => theme.colors.white};
  text-align: center;

  &:hover {
    ${({ fileIsUploading }): string =>
      fileIsUploading ? 'cursor: wait;' : 'cursor: pointer;'};
  }
`;

const DropzoneContentWrapper = styled.div`
  width: 100%;
  height: 75%;
`;

const FileInput = styled.input``;

const FileTypes = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }): string => theme.colors.black90Alpha};
`;

const Instructions = styled.div`
  font-size: 14px;
  padding-top: 4px;
  color: ${({ theme }): string => theme.colors.black50Alpha};
  padding-left: 20px;
  padding-right: 20px;
`;

const Browser = styled.span`
  color: ${({ theme }): string => theme.colors.brandBlue};
`;

export type DropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: string;
  'data-testid'?: string;
  activeUploadFilename?: string;
};

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop: handleDrop,
  accept = 'image/jpeg, .png, application/pdf',
  'data-testid': dataTestId = 'Dropzone',
  activeUploadFilename,
}) => {
  const validFileTypes = accept
    .split(',')
    .map((acceptedType) => {
      const trimmedType = acceptedType.trim();
      if (trimmedType.includes('.')) {
        // just assume it's a .* ext provided
        return trimmedType.toUpperCase();
      }
      if (trimmedType.includes('/')) {
        // assuming it's a mimetype like image/jpeg
        return `.${trimmedType.split('/')[1].toUpperCase()}`;
      }
      return '';
    })
    .join(' ');

  const [fileHasBeenDraggedIn, setFileHasBeenDraggedIn] =
    useState<boolean>(false);
  const [uploadingFileName, setUploadingFileName] = useState<
    string | undefined
  >(activeUploadFilename);
  useEffect(() => {
    setUploadingFileName(activeUploadFilename);
  }, [activeUploadFilename]);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setFileHasBeenDraggedIn(false); // reset flag for animation
      await handleDrop(acceptedFiles);
      setUploadingFileName(undefined);
    },
    [handleDrop]
  );

  const onDragEnter = useCallback(() => {
    if (!fileHasBeenDraggedIn) {
      setFileHasBeenDraggedIn(true);
    }
  }, [fileHasBeenDraggedIn]);

  const onDragLeave = useCallback(() => {
    if (fileHasBeenDraggedIn) {
      setFileHasBeenDraggedIn(false);
    }
  }, [fileHasBeenDraggedIn]);

  const [invalidFileDropped, setInvalidFileDropped] = useState<boolean>(false);
  const onDropRejected = useCallback(() => {
    setFileHasBeenDraggedIn(false);
    setInvalidFileDropped(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted: onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected,
  });
  const [playHoverAnimation, setPlayHoverAnimation] = useState<boolean>(false);
  return (
    <Box
      {...getRootProps()}
      fileIsUploading={Boolean(uploadingFileName)}
      data-testid={dataTestId}
      onMouseEnter={() => {
        setPlayHoverAnimation(true);
      }}
      onMouseLeave={() => {
        setPlayHoverAnimation(false);
      }}
    >
      {uploadingFileName ? (
        <DropzoneContentWrapper>
          <LoadingAnimation />
          <Instructions data-testid={`${dataTestId}_CurrentlyUploadingText`}>
            Uploading {uploadingFileName}
          </Instructions>
        </DropzoneContentWrapper>
      ) : (
        <DropzoneContentWrapper>
          <FileInput
            {...getInputProps()}
            data-testid={`${dataTestId}_FileInput`}
          />
          {fileHasBeenDraggedIn && <DropAnimation />}
          {!fileHasBeenDraggedIn && !invalidFileDropped && (
            <HoverAnimation play={playHoverAnimation} />
          )}
          {invalidFileDropped && !fileHasBeenDraggedIn && (
            <UnsupportedAnimation
              onAnimationEnd={() => {
                setInvalidFileDropped(false);
              }}
            />
          )}

          <FileTypes data-testid={`${dataTestId}_FileTypes`}>
            {validFileTypes}
          </FileTypes>
          <Instructions data-testid={`${dataTestId}_UploadInstruction`}>
            Drag and drop your file here or <Browser>browse</Browser>
          </Instructions>
        </DropzoneContentWrapper>
      )}
    </Box>
  );
};

export default Dropzone;
