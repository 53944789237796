import React from 'react';
import BasicSelect from './BasicSelect';
import SearchableSelect from './SearchableSelect';
import { ComponentWrapper } from './selectStyles';

export type MenuItemDefinition = {
  value: string;
  label: string;
};

interface CommonProps {
  items: MenuItemDefinition[];
  onSelect: (item: MenuItemDefinition | null | undefined) => void;
  placeholder?: string;
  'data-testid'?: string;
}

// Ensure `value` is passed if `isSearchable` is true
type SearchableProps =
  | { isSearchable?: false; value?: MenuItemDefinition }
  | { isSearchable: true; value: MenuItemDefinition };

export type SelectProps = CommonProps & SearchableProps;

export const Select = ({
  isSearchable = false,
  items,
  onSelect,
  placeholder,
  value: initialValue,
  'data-testid': dataTestId = 'Select',
}: SelectProps): JSX.Element => {
  return (
    <ComponentWrapper data-testid={dataTestId}>
      {isSearchable ? (
        <SearchableSelect
          items={items}
          onSelect={onSelect}
          placeholder={placeholder}
          value={initialValue}
        />
      ) : (
        <BasicSelect
          items={items}
          onSelect={onSelect}
          placeholder={placeholder}
          value={initialValue}
        />
      )}
    </ComponentWrapper>
  );
};

export default Select;
