/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import DropStart from './dropStart.json';
import DropLoop from './dropLoop.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Animation = styled(Player)<{ size: number }>`
  height: 100px;
  width: 100px;
`;

type DropAnimationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  src?: any;
};

export const DropAnimation: React.FC<DropAnimationProps> = () => {
  const [displayLoopingArrow, setDisplayLoopingArrow] =
    useState<boolean>(false);
  return (
    <Wrapper data-testid="DropAnimation">
      <LottieWrapper>
        {!displayLoopingArrow ? (
          <Animation
            autoplay
            src={DropStart}
            onEvent={(event: string) => {
              if (event === 'pause') {
                setDisplayLoopingArrow(true);
              }
            }}
          />
        ) : (
          <Animation autoplay src={DropLoop} loop />
        )}
      </LottieWrapper>
    </Wrapper>
  );
};

export default DropAnimation;
