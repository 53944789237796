import { motion } from 'framer-motion';
import InlineSVG from 'react-inlinesvg';
import styled from 'styled-components/macro';
import { trustFlight } from '../../styles/themes';

export const CloseModalIcon = styled(InlineSVG)`
  width: 12px;
  height: auto;
`;

export const CloseModalBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 24px;
  z-index: 205;
  &:hover {
    & ${CloseModalIcon} {
      fill: var(--brand-blue);
    }
  }
  &:focus {
    box-shadow: 0 0 0 1px var(--brand-blue);
    & ${CloseModalIcon} {
      fill: var(--brand-blue);
    }
  }
  &:focus-visible {
    box-shadow: 0 0 0 1px var(--brand-blue);
    outline: none;
    & ${CloseModalIcon} {
      fill: var(--brand-blue);
    }
  }
  &:active {
    background-color: var(--brand-blue-15);
    box-shadow: none;
    & ${CloseModalIcon} {
      fill: var(--brand-blue);
    }
  }
`;

type ModalCardProps = {
  $width: number;
};

export const ModalCard = styled(motion.div)<ModalCardProps>`
  background-color: ${({ theme }): string => theme.colors.snow};
  border: 2px solid ${({ theme }): string => theme.colors.white};
  border-radius: 2px;
  box-shadow: 0 0 10px 0 ${({ theme }): string => theme.colors.black20Alpha};
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
  padding: 40px;
  ${(): string => trustFlight.media.smallUp} {
    width: ${({ $width }): string => ($width ? `${$width}px` : '600px')};
  }
`;
export const ModalCardVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 50,
  },
};

export const ModalContent = styled.div`
  height: 100%;
  max-height: calc(100vh - 45px);
  width: 100%;
`;

type ModalWrapperProps = {
  $isModalOpen: boolean;
};

export const ModalWrapper = styled(motion.div)<ModalWrapperProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: ${({ theme }): string => theme.colors.black40Alpha};
  display: ${({ $isModalOpen }): string => ($isModalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 999;
`;
export const ModalWrapperVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
