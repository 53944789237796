import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import Error from './error.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
`;

const Animation = styled(Player)<{ size: number }>`
  height: 30px;
  width: 30px;
`;

export const ErrorAnimation: React.FC = () => {
  return (
    <Wrapper data-testid="ErrorAnimation">
      <LottieWrapper>
        <Animation src={Error} autoplay keepLastFrame />
      </LottieWrapper>
    </Wrapper>
  );
};

export default ErrorAnimation;
