import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import dropdownIconSvg from '../../assets/dropdown-icon.svg';
import {
  DropdownIcon,
  DropdownWrapper,
  MenuItem,
  MenuOuterWrapper,
  MenuWrapper,
  SelectBox,
  SelectInputBox,
} from './selectStyles';

export type MenuItemDefinition = {
  value: string;
  label: string;
};

export type BasicSelectProps = {
  items: MenuItemDefinition[];
  onSelect: (item: MenuItemDefinition | null | undefined) => void;
  placeholder?: string;
  value?: MenuItemDefinition;
  'data-testid'?: string;
};

const defaultPlaceholderText = 'Please select...';

export const SearchableSelect = ({
  items,
  onSelect,
  placeholder = defaultPlaceholderText,
  value: incomingMenuItem,
  'data-testid': dataTestId = 'SearchableSelect',
}: BasicSelectProps): JSX.Element => {
  const [value, setValue] = useState<MenuItemDefinition | undefined | null>(
    incomingMenuItem
  );
  const [inputItems, setInputItems] = useState(items);
  const [placeholderText, setPlaceholderText] = useState(placeholder);

  const itemToString = (item: MenuItemDefinition | null) =>
    item ? item.label : '';

  useEffect(() => {
    setValue(incomingMenuItem);
  }, [incomingMenuItem]);

  const stateReducer = (
    state: UseComboboxState<MenuItemDefinition>,
    actionAndChanges: UseComboboxStateChangeOptions<MenuItemDefinition>
  ) => {
    const { type, changes } = actionAndChanges;

    const shouldSetIncomingMenuItem =
      type === '__input_blur__' &&
      changes.inputValue === '' &&
      incomingMenuItem;
    const shouldDefaultToFirstItem =
      type === '__input_blur__' && !incomingMenuItem;
    const shouldHighlightSelectedItem =
      type === '__togglebutton_click__' &&
      _.some(state.inputValue) &&
      _.some(changes.inputValue) &&
      changes.inputValue === state.inputValue;

    if (shouldHighlightSelectedItem && state.selectedItem) {
      const selectedMenuItemIndex = items.indexOf(state.selectedItem);

      return {
        ...changes,
        highlightedIndex: selectedMenuItemIndex,
        inputValue: '',
      };
    }

    if (type === '__togglebutton_click__') {
      return { ...changes, inputValue: '' };
    }

    if (shouldDefaultToFirstItem) {
      return {
        ...changes,
        inputValue: inputItems[0].label,
        selectedItem: inputItems[0],
      };
    }

    if (shouldSetIncomingMenuItem) {
      return {
        ...changes,
        inputValue: incomingMenuItem?.label || '',
      };
    }

    return changes;
  };

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getComboboxProps,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    itemToString,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        items.filter((item) =>
          item.label
            .toLowerCase()
            .includes((inputValue as string).toLowerCase())
        )
      );
    },
    onSelectedItemChange: (changes) => {
      setValue(changes.selectedItem);
      onSelect(changes.selectedItem);
    },
    stateReducer,
    circularNavigation: true,
    initialSelectedItem: value,
  });

  const handleOnFocus = () => {
    setValue(null);
    setPlaceholderText(selectedItem?.label || placeholder);
  };

  return (
    <>
      <SelectBox
        tabIndex={0}
        isSearchable
        itemIsSelected={Boolean(selectedItem)}
        menuIsOpen={isOpen}
        data-testid={`${dataTestId}_SelectBox`}
        {...getToggleButtonProps()}
        {...getComboboxProps()}
      >
        <SelectInputBox
          data-testid={`${dataTestId}_Input`}
          placeholder={placeholderText}
          {...getInputProps({
            onFocus: handleOnFocus,
          })}
        />
        <DropdownWrapper
          menuIsOpen={isOpen}
          data-testid={`${dataTestId}_DropdownWrapper`}
        >
          <DropdownIcon src={dropdownIconSvg} />
        </DropdownWrapper>
      </SelectBox>
      <MenuOuterWrapper>
        <MenuWrapper
          isOpen={isOpen}
          data-testid={`${dataTestId}_MenuWrapper`}
          {...getMenuProps()}
        >
          {isOpen &&
            inputItems.map((item, index) => (
              <MenuItem
                key={`${item.value}`}
                isSelected={selectedItem?.value === item.value}
                isHighlighted={highlightedIndex === index}
                data-testid={`${dataTestId}_MenuItem-${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </MenuItem>
            ))}
        </MenuWrapper>
      </MenuOuterWrapper>
    </>
  );
};

export default SearchableSelect;
