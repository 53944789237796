import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import type { Editor } from 'slate';
import styled from 'styled-components';
import { Button } from '../../Button/Button';
import { createAndEditTable } from '../utils';

type TablePopupButtonsProps = {
  numOfColumns: number | null;
  numOfRows: number | null;
  buttonLabels: Record<string, ReactNode>;
  type: string;
  menuToggle: Dispatch<SetStateAction<boolean>>;
  deleteTable: boolean;
  editor: Editor;
  onBlur: (overrideFocus: boolean) => void;
};

const Wrapper = styled.div<{ type: string }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
`;

const SecondaryButton = styled.button`
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.brandBlue};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

export default ({
  numOfColumns,
  numOfRows,
  buttonLabels: { primary, secondary },
  type,
  menuToggle,
  deleteTable = false,
  editor,
  onBlur,
}: TablePopupButtonsProps): JSX.Element => (
  <Wrapper type={type}>
    <SecondaryButton
      onClick={(): void => {
        menuToggle(false);
        if (type === 'editButtons' && numOfColumns && numOfRows)
          createAndEditTable({
            editor,
            numOfColumns,
            numOfRows,
            deleteTable,
            onBlur,
          });
      }}
      type="button"
    >
      {secondary}
    </SecondaryButton>
    <Button
      onClick={(): void => {
        if (numOfColumns && numOfRows) {
          createAndEditTable({
            editor,
            numOfColumns,
            numOfRows,
            onBlur,
            deleteTable: false,
          });
          menuToggle(false);
        }
      }}
      disabled={!numOfColumns || !numOfRows}
    >
      {primary}
    </Button>
  </Wrapper>
);
