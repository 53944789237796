import _ from 'lodash';
import pipe from 'lodash/fp/pipe';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createEditor, Descendant, Editor, Node } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import styled from 'styled-components';
import { defaultValue } from '../../utils/utils';
import Element from './Element';
import Leaf from './Leaf';
import withImageReferencesPlugin from './plugins/withImageReferences';
import withImages from './plugins/withImages';
import ToolBar from './ToolBar';
import {
  handleBackspaceInsideAlertBox,
  handleBackspaceInsideList,
  handleBackspaceInTableCell,
  handleDeleteInsideImageReference,
  handleEnterInsideImageReference,
  handleKeyStrokesInAlertBox,
  isInsideEmptyAlertBox,
  isInsideEmptyListItem,
  isInsideEmptyTableCell,
  isInsideNode,
  sanitiseText,
} from './utils';

export interface ImageMetadata {
  id: string;
  description?: string;
  filename: string;
  src: string;
}

export type RichTextEditorProps = {
  images?: ImageMetadata[];
  initialValue?: SetStateAction<Node[]>;
  isDisabled?: boolean;
  onChange?: (newValue: Node[]) => void;
  onBlur?: (name: string, value: Node[]) => void;
  name: string;
  placeholder?: string;
  withImageReferences?: boolean;
  initHeight?: string;
  fixedToolbar?: boolean;
};

const Wrapper = styled.div<Pick<RichTextEditorProps, 'initHeight'>>`
  background-color: var(--white);
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  border-radius: 2px;
  max-width: 100%;
  min-height: 150px;
  height: ${({ initHeight }) => (initHeight ? `${initHeight}` : 'auto')};
  overflow-y: scroll;
  padding: 0 10px 10px;
  position: relative;
  resize: both;
  p {
    line-height: 20px;
  }
`;

const withPlugins = pipe(
  withReact,
  withHistory,
  withImages,
  withImageReferencesPlugin
);

const RichTextEditor = ({
  fixedToolbar = false,
  images,
  initialValue = [],
  isDisabled = false,
  initHeight,
  name,
  onChange,
  onBlur,
  placeholder = '...',
  withImageReferences = false,
}: RichTextEditorProps): JSX.Element => {
  const [value, setValue] = useState<Descendant[] | Node[]>([]);
  const [resetMenus] = useState(false);
  const editor = useMemo(() => withPlugins(createEditor() as ReactEditor), []);
  const allowSave = useRef<boolean>(false);

  const handleOnBlur = (overrideFocus: boolean): void => {
    if (onBlur && (overrideFocus || allowSave.current)) {
      onBlur(name, value);
    }
  };
  useEffect(() => {
    if (_.isEqual(value, defaultValue) || value.length === 0) {
      try {
        setValue((initialValue.length > 0 && initialValue) || defaultValue);
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, editor]);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [autoSanitiseIsEnabled, setAutoSanitiseIsEnabled] =
    useState<boolean>(false);
  const handleEditableKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (
      isInsideEmptyListItem(editor) &&
      (event.key === 'Backspace' || event.key === 'Enter')
    ) {
      handleBackspaceInsideList(editor, event);
    } else if (
      isInsideNode(editor, 'image-reference') &&
      event.key === 'Enter'
    ) {
      handleEnterInsideImageReference(editor, event);
    } else if (
      isInsideNode(editor, 'image-reference') &&
      (event.key === 'Backspace' || event.key === 'Delete')
    ) {
      handleDeleteInsideImageReference({
        editor,
      });
    } else if (isInsideEmptyTableCell(editor) && event.key === 'Backspace') {
      handleBackspaceInTableCell(editor, event);
    } else if (isInsideEmptyAlertBox(editor) && event.key === 'Backspace') {
      handleBackspaceInsideAlertBox(editor);
    } else if (event.key === 'Enter') {
      handleKeyStrokesInAlertBox({ editor, event });
    } else if (event.shiftKey && event.key === 'ArrowDown') {
      handleKeyStrokesInAlertBox({ editor, event, isExitingBox: true });
    }
  };

  return (
    <Wrapper data-testid="RichTextEditor" initHeight={initHeight}>
      <Slate
        editor={editor}
        value={value as Descendant[]}
        onChange={(newValue) => {
          setValue(newValue);
          if (onChange) {
            onChange(newValue);
          }
        }}
      >
        {!isDisabled && (
          <ToolBar
            editor={editor}
            fixedToolbar={fixedToolbar}
            onBlur={handleOnBlur}
            images={images}
            resetMenus={resetMenus}
            value={value}
            withImageReferences={withImageReferences}
            onSanitiseChange={(sanitiseValue) => {
              setAutoSanitiseIsEnabled(sanitiseValue);
            }}
          />
        )}
        <Editable
          readOnly={isDisabled}
          onKeyDown={(event) => handleEditableKeyDown(event)}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          style={{
            // default position relative conflicts with fixed nav sticky box flow
            position: 'unset',
          }}
          onBlur={() => {
            handleOnBlur(true);
          }}
          onPaste={(event) => {
            if (autoSanitiseIsEnabled) {
              event.preventDefault();
              const paste = sanitiseText(event.clipboardData.getData('text'));
              Editor.insertText(editor, paste);
            }
          }}
        />
      </Slate>
    </Wrapper>
  );
};

export default RichTextEditor;
