import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import Tick from './tick.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
`;

const Animation = styled(Player)<{ size: number }>`
  height: 30px;
  width: 30px;
`;

export const TickAnimation: React.FC = () => {
  return (
    <Wrapper data-testid="TickAnimation">
      <LottieWrapper>
        <Animation src={Tick} autoplay keepLastFrame />
      </LottieWrapper>
    </Wrapper>
  );
};

export default TickAnimation;
