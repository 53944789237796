import React, { memo, useCallback } from 'react';
import { Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { ColorPaletteItem } from './ColorMenu';

interface ColorButtonProps {
  type: 'textColor' | 'highlightColor';
  color: string;
}

export default memo(({ type, color }: ColorButtonProps) => {
  const editor: Editor = useSlate();
  const marks = Editor.marks(editor);
  const isActive = marks && marks[type] === color;

  const handleToggleColour = useCallback(
    (event): void => {
      event.preventDefault();
      if (marks && marks[type]) {
        Editor.removeMark(editor, type);
      }
      Editor.addMark(editor, type, color);
      ReactEditor.focus(editor);
    },
    [marks, type, editor, color]
  );

  return (
    <ColorPaletteItem
      type="button"
      color={color}
      isActive={Boolean(isActive)}
      onMouseDown={handleToggleColour}
    />
  );
});
