import styled from 'styled-components';

export const AddImageMenuOuterWrapper = styled.div`
  position: fixed;
  z-index: 1;
`;

export const AddImageMenuWrapper = styled.div`
  background: var(--white);
  border: 1px solid var(--white);
  border-radius: 4px;
  box-shadow: 0 0 10px 0 var(--black-10);
  font-size: 13px;
  margin-right: 10px;
`;

export const EditorImage = styled.img<{ isSelected?: boolean }>`
  display: block;
  max-width: 100%;
  max-height: 20em;
  box-shadow: ${({ isSelected }): string =>
    isSelected ? '0 0 0 3px #B4D5FF' : 'none'};
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  .description {
    background-color: var(--white-80);
    border: none;
    color: var(--black-80);
    font-family: var(--font-family--main);
    font-size: 1rem;
    line-height: 20px;
    padding: 5px;
    text-align: center;
  }
`;
