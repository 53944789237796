import {
  Ancestor,
  Editor,
  Element as SlateElement,
  Node,
  NodeEntry,
  Transforms,
} from 'slate';
import tableFactory from './TableMenu/tableFactory';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const paragraphNode = { type: 'paragraph', children: [{ text: '' }] };

export const colorMatch = new RegExp(/#\w+/);

export const colors: Record<string, Record<string, string>> = {
  text: {
    black: '#000000',
    blue: '#1600ff',
    yellow: '#ffb84c',
  },
  highlight: {
    pink: '#fe02fe',
    flYellow: '#fef202',
    green: '#00ff00',
  },
};

export const isBlockActive = (editor: Editor, format: string): boolean => {
  try {
    const [match] = Editor.nodes(editor, {
      match: (n: Node) => (n as SlateElement).type === format,
    });

    return !!match;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const isMarkActive = (editor: Editor, format: string): boolean => {
  try {
    const marks: Record<string, boolean> = Editor.marks(editor) as Record<
      string,
      boolean
    >;
    return marks ? marks[format] === true : false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const toggleBlock = (editor: Editor, format: string): void => {
  try {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n: Node) =>
        LIST_TYPES.includes((n as SlateElement).type as string),
      split: true,
    });

    let type: string;
    if (isActive) {
      type = 'paragraph';
    } else if (isList) {
      type = 'list-item';
    } else {
      type = format;
    }

    Transforms.setNodes(editor, {
      type,
    });

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  } catch (e) {
    console.error(e);
  }
};

export const toggleMark = (
  editor: Editor,
  format: 'bold' | 'italic' | 'underline'
): void => {
  try {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getTableDimension = (
  editor: Editor,
  type: string
): Record<string, number> => {
  const path = editor.selection
    ? editor.selection.anchor.path.slice(0, 1)[0]
    : 0;
  const { children } = editor;
  const selectedTable: Record<string, number> = children[
    path
  ] as unknown as Record<string, number>;

  if (type === 'both') {
    return { rows: selectedTable.rows, cols: selectedTable.cols };
  }

  return selectedTable;
};

type CreateAndEditTableProps = {
  editor: Editor;
  numOfColumns: number;
  numOfRows: number;
  deleteTable: boolean;
  onBlur: (overrideFocus: boolean) => void;
};

export const createAndEditTable = ({
  editor,
  numOfColumns,
  numOfRows,
  deleteTable = false,
  onBlur,
}: CreateAndEditTableProps): void => {
  const tableIsSelected = isBlockActive(editor, 'table-row');
  const table = tableFactory({ editor, numOfColumns, numOfRows });
  if (deleteTable) {
    try {
      Transforms.removeNodes(editor, {
        match: (n: Node) => (n as SlateElement).type === 'table',
      });
    } catch (e) {
      console.error(e);
    }
    onBlur(true);
  }

  if (!tableIsSelected) {
    // if a table isn't currently selected create a new one
    table.makeNewTable();
  }

  if (tableIsSelected) {
    try {
      // edit currently selected table
      table.setPath();
      table.currentDimensions = getTableDimension(editor, 'both');

      if (table.rowsShouldBeIncreased()) {
        table.setStartIndex();
        table.addRows();
      } else if (table.rowsShouldBeDecreased()) {
        table.removeRows();
      }

      if (table.colsShouldBeIncreased()) {
        table.addColumns();
      } else if (table.colsShouldBeDecreased()) {
        table.removeColumns();
      }
      // once editing is complete, update the tables cols and rows helper properties
      table.updateTableSizeProperties();
      onBlur(true);
    } catch (e) {
      console.error(e);
    }
  }
};

export const ensureSelection = (editor: Editor): void => {
  if (!editor.selection) {
    Transforms.select(editor, {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    });
  }
};

export const isInsideEmptyTableCell = (editor: Editor): boolean => {
  const point = editor?.selection?.focus || {
    path: [0],
    offset: 0,
  };
  const path = editor?.selection?.focus?.path || [0];
  const [emptyTableCell]: Generator<
    NodeEntry<Node>,
    void,
    undefined
  > = Editor.nodes(editor, {
    match: (n: Node) =>
      (n as SlateElement).type === 'table-cell' &&
      Editor.isStart(editor, point, path),
  });
  return Boolean(emptyTableCell);
};

export const isInsideEmptyListItem = (editor: Editor): boolean => {
  const point = editor?.selection?.focus || {
    path: [0],
    offset: 0,
  };
  const path = editor?.selection?.focus?.path || [0];
  const [emptyListItem]: Generator<
    NodeEntry<Node>,
    void,
    undefined
  > = Editor.nodes(editor, {
    match: (n: Node) =>
      (n as SlateElement).type === 'list-item' &&
      Editor.isStart(editor, point, path),
  });
  return Boolean(emptyListItem);
};

export const isInsideEmptyNode = (
  editor: Editor,
  nodeType: string
): boolean => {
  const point = editor?.selection?.focus || {
    path: [0],
    offset: 0,
  };
  const path = editor?.selection?.focus?.path || [0];
  const [cellType]: Generator<NodeEntry<Node>, void, undefined> = Editor.nodes(
    editor,
    {
      match: (n: Node) =>
        (n as SlateElement).type === nodeType &&
        Editor.isStart(editor, point, path),
    }
  );

  return Boolean(cellType);
};

export const isInsideNode = (editor: Editor, nodeType: string): boolean => {
  const [match]: Generator<NodeEntry<Node>, void, undefined> = Editor.nodes(
    editor,
    {
      match: (n: Node) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === nodeType,
    }
  );

  return !!match;
};

export const isInsideAlertBox = (editor: Editor): boolean =>
  isInsideNode(editor, 'caution-box') || isInsideNode(editor, 'warning-box');

export const isInsideEmptyAlertBox = (editor: Editor): boolean =>
  isInsideEmptyNode(editor, 'caution-box') ||
  isInsideEmptyNode(editor, 'warning-box');

export const sanitiseText = (value: string): string => {
  let text = value.replace(/(\n|\r)/gm, ' ');
  // Replace multiple spaces, then trim to remove leading/trailing spaces
  text = text.replace(/\s+/gm, ' ').trim();
  // Find space-single_char-bracket-space combos; replace the first space with a newline
  text = text.replace(/\s([a-z]{1}\)\s)/gm, '\n$1');
  // replace spaces after NOTE: with newline
  text = text.replace(/((NOTE|NOTES|WARNING|CAUTION)\s?[1-9]?:)/gm, '\n\n$1');
  return text;
};

interface AddNewLineInsideAlertBoxArgs {
  editor: Editor;
  event: React.KeyboardEvent<HTMLDivElement>;
  isExitingBox?: boolean;
}
export const handleKeyStrokesInAlertBox = ({
  editor,
  event,
  isExitingBox,
}: AddNewLineInsideAlertBoxArgs): void => {
  const [alertBox]: Generator<NodeEntry<Node>, void, undefined> = Editor.nodes(
    editor,
    {
      match: (n: Node) =>
        (n as SlateElement).type === 'caution-box' ||
        (n as SlateElement).type === 'warning-box',
    }
  );

  if (alertBox) {
    event.preventDefault();

    if (isExitingBox) {
      Transforms.insertNodes(editor, paragraphNode);
    } else {
      Transforms.insertText(editor, '\n\n');
    }
  }
};

export const handleEnterInsideImageReference = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  const [imageReference]: Generator<
    NodeEntry<Node>,
    void,
    undefined
  > = Editor.nodes(editor, {
    match: (n: Node) => (n as SlateElement).type === 'image-reference',
  });

  if (imageReference) {
    event.preventDefault();
    Transforms.insertNodes(editor, paragraphNode);
  }
};

interface ImageReferenceBackspaceArgs {
  editor: Editor;
}
export const handleDeleteInsideImageReference = ({
  editor,
}: ImageReferenceBackspaceArgs): void => {
  try {
    Transforms.removeNodes(editor, {
      match: (n: Node) => (n as SlateElement).type === 'image-reference',
    });
  } catch (e) {
    console.error(e);
  }
};

export const handleBackspaceInsideAlertBox = (editor: Editor): void => {
  // We want to remove empty alert boxes on backspace/delete
  Transforms.setNodes(editor, paragraphNode);
};

export const handleBackspaceInsideList = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  const numberedListIsActive = isBlockActive(editor, 'numbered-list');
  event.preventDefault();
  if (numberedListIsActive) {
    toggleBlock(editor, 'numbered-list');
  } else {
    toggleBlock(editor, 'bulleted-list');
  }
};

export const handleBackspaceInTableCell = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  // we want to prevent cell deletion from tables
  if (editor.selection) {
    const parent = Editor.parent(editor, editor.selection.focus.path, {
      edge: 'start',
    });
    const grandParent: Ancestor = Editor.parent(editor, parent[1])[0];
    if (
      (grandParent as SlateElement).type === 'table-cell' &&
      grandParent.children.length < 2
    ) {
      event.preventDefault();
    }
  }
};
