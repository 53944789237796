import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import type { Descendant, Editor } from 'slate';
import styled from 'styled-components';
import Bold from '../../assets/RichTeIcons/icon-bold.svg';
import Underline from '../../assets/RichTeIcons/icon-editor-underline.svg';
import HighlightColour from '../../assets/RichTeIcons/icon-highlight-blank.svg';
import Italic from '../../assets/RichTeIcons/icon-italic.svg';
import TextColourLetterBlack from '../../assets/RichTeIcons/icon-text-colour-black.svg';
import AddImageButton from './AddImage/AddImageButton';
import BlockButtons from './BlockButtons';
import ClearColorButton from './ColorMenu/ClearColorButton';
import ColorButton from './ColorMenu/ColorButton';
import ColorMenu from './ColorMenu/ColorMenu';
import IndentationButtons from './IndentationButtons';
import MarkButton from './MarkButton';
// import MoreOptionsButton from './MoreOptionsButton';
import RedoButton from './RedoButton';
import type { ImageMetadata, RichTextEditorProps } from './RichTextEditor';
import SanitiseTool from './SanitiseTool';
import TableButton from './TableButton';
import { toolBarColors } from './ToolBarStyles';
import UndoButton from './UndoButton';

type RichTextToolbarProps = {
  fixedToolbar: boolean;
  onBlur: (overrideFocus: boolean) => void;
  editor: Editor;
  images: ImageMetadata[] | undefined;
  resetMenus: boolean;
  value: Node[] | Descendant[];
  onSanitiseChange: (value: boolean) => void;
  withImageReferences: boolean;
};

const ToolBarWrapper = styled.div<Pick<RichTextEditorProps, 'fixedToolbar'>>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  background-color: ${({ theme }): string => theme.colors.white};
  padding-top: 10px;
  position: ${({ fixedToolbar }) => (fixedToolbar ? `sticky` : 'relative')};
  top: 0;
`;

const Divider = styled.span`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }): string => theme.colors.frostedSteel};
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
`;

const UndoRedoWrapper = styled.div`
  display: flex;
  flex: 1 0 10%;
  justify-content: flex-end;
`;

export default ({
  fixedToolbar = false,
  onBlur,
  editor,
  images,
  onSanitiseChange,
  resetMenus,
  value,
  withImageReferences,
}: RichTextToolbarProps): JSX.Element => {
  const [highlightMenu, toggleHighlightMenu] = useState(false);
  const [textMenu, toggleTextMenu] = useState(false);
  const [tableMenu, createAndEditTableMenu] = useState(false);

  useEffect(() => {
    toggleHighlightMenu(false);
    toggleTextMenu(false);
    createAndEditTableMenu(false);
  }, [resetMenus]);

  return (
    <ToolBarWrapper data-testid="ToolBar" fixedToolbar={fixedToolbar}>
      <MarkButton format="bold" icon={Bold} data-testid="BoldButton" />
      <MarkButton format="italic" icon={Italic} data-testid="ItalicButton" />
      <MarkButton
        format="underline"
        icon={Underline}
        data-testid="UnderlineButton"
      />
      <Divider />
      <ColorMenu
        showMenu={textMenu}
        menuToggle={toggleTextMenu}
        toggleOtherMenus={{
          siblingColourMenu: toggleHighlightMenu,
          tableMenu: createAndEditTableMenu,
        }}
        otherMenuStates={[highlightMenu, tableMenu]}
        type="textColor"
        label="Text color"
        icon={TextColourLetterBlack}
      >
        {Object.values(toolBarColors.textColor).map((color) => (
          <ColorButton
            type="textColor"
            color={color}
            key={`textColorButton-${color}`}
          />
        ))}
      </ColorMenu>
      <ColorMenu
        type="highlightColor"
        showMenu={highlightMenu}
        menuToggle={toggleHighlightMenu}
        toggleOtherMenus={{
          siblingColourMenu: toggleTextMenu,
          tableMenu: createAndEditTableMenu,
        }}
        otherMenuStates={[textMenu, tableMenu]}
        label="Highlight color"
        icon={HighlightColour}
      >
        <>
          {Object.values(toolBarColors.highlightColor).map((color) => (
            <ColorButton
              type="highlightColor"
              color={color}
              key={`highlightColorButton-${color}`}
            />
          ))}
          <ClearColorButton type="highlightColor" />
        </>
      </ColorMenu>
      <Divider />
      {_.some(images) && (
        <>
          <AddImageButton
            images={images}
            withImageReferences={withImageReferences}
          />
          <Divider />
        </>
      )}
      <BlockButtons />
      <Divider />
      <TableButton
        showMenu={tableMenu}
        toggleTextMenu={toggleTextMenu}
        toggleHighlightMenu={toggleHighlightMenu}
        otherMenuStates={[textMenu, highlightMenu]}
        menuToggle={createAndEditTableMenu}
        onBlur={onBlur}
      />
      <Divider />
      <IndentationButtons />
      <Divider />
      <SanitiseTool
        onChange={(sanitiseValue) => {
          onSanitiseChange(sanitiseValue);
        }}
      />
      <Divider />
      {/* TODO: Add when designs are finalised */}
      {/* <MoreOptionsButton /> */}
      <UndoRedoWrapper>
        <UndoButton editor={editor} value={value} />
        <RedoButton editor={editor} value={value} />
      </UndoRedoWrapper>
    </ToolBarWrapper>
  );
};
