import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ContentsWrapper, MenuTitle, OtherContent } from './ContentsMenuStyles';
import SectionMenu, { ContentMenuItem, ContentSection } from './SectionMenu';

export type ContentsMenuProps = {
  activeValue?: ContentMenuItem;
  children?: React.ReactNode;
  contents: ContentSection[];
  hasDropdownChevron?: boolean;
  menuTitle: string;
  onSelect: (item: ContentMenuItem) => void;
  stickAtYPosition?: number;
  stickOnOffset?: number;
  willStick?: boolean;
};

export const ContentsMenu = ({
  activeValue,
  children,
  contents,
  hasDropdownChevron = false,
  menuTitle,
  onSelect,
  stickAtYPosition = 0,
  stickOnOffset = 0,
  willStick = false,
}: ContentsMenuProps): JSX.Element => {
  const [itemsExpanded, setItemsExpanded] = useState(new Set());
  const [itemSelected, setItemSelected] = useState<ContentMenuItem>();
  const [isBeyondStickyOffset, setIsBeyondStickyOffset] = useState<boolean>();

  const handleScroll = (): void => {
    if (window) {
      const newIsBeyondStickyOffset = window.pageYOffset >= stickOnOffset;
      if (newIsBeyondStickyOffset !== isBeyondStickyOffset) {
        setIsBeyondStickyOffset(newIsBeyondStickyOffset);
      }
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }
  });

  useEffect(() => {
    if (activeValue) {
      setItemSelected(activeValue);
    }
  }, [activeValue, setItemSelected]);

  const handleSectionClick = (content: ContentSection) => {
    const nothingIsExpanded = itemsExpanded.size === 0;
    const clickedItemIsTheOnlyExpandedItem =
      itemsExpanded.has(content) && itemsExpanded.size === 1;
    const oneItemIsExpandedAndSomethingIsSelected =
      itemsExpanded.size === 1 && itemSelected;
    const oneItemIsExpandedAndNothingIsSelected =
      itemsExpanded.size === 1 && !itemSelected;
    const twoItemsAreExpandedIncludingTheClickedItem =
      itemsExpanded.size === 2 && itemsExpanded.has(content);
    const twoItemsAreExpandedAndNeitherAreTheClickedItem =
      itemsExpanded.size === 2 && !itemsExpanded.has(content) && itemSelected;

    if (nothingIsExpanded) {
      setItemsExpanded(new Set([content]));
    } else if (clickedItemIsTheOnlyExpandedItem) {
      setItemsExpanded(new Set());
    } else if (oneItemIsExpandedAndNothingIsSelected) {
      setItemsExpanded(new Set([content]));
    } else if (oneItemIsExpandedAndSomethingIsSelected) {
      const selectedItemsParent = contents.find((parentItem: ContentSection) =>
        parentItem.items?.includes(itemSelected as ContentMenuItem)
      );
      if (itemsExpanded.has(selectedItemsParent)) {
        // retain parent of selected item
        setItemsExpanded(new Set([selectedItemsParent, content]));
      } else {
        setItemsExpanded(new Set([content]));
      }
    } else if (twoItemsAreExpandedIncludingTheClickedItem) {
      // two things expanded including this, so just retain the other item
      const newItemsExpanded = Array.from(itemsExpanded).filter(
        (expandedItem) => expandedItem !== content
      );
      setItemsExpanded(new Set(newItemsExpanded));
    } else if (twoItemsAreExpandedAndNeitherAreTheClickedItem) {
      // something else and selection parent expanded
      const selectedItemsParent = contents.filter(
        (parentItem: ContentSection) =>
          parentItem.items?.includes(itemSelected as ContentMenuItem)
      )[0];
      setItemsExpanded(new Set([selectedItemsParent, content]));
    }
  };

  return (
    <ContentsWrapper
      isSticky={Boolean(willStick && isBeyondStickyOffset)}
      stickPosition={stickAtYPosition}
      data-testid="ContentsWrapper"
    >
      <MenuTitle>{menuTitle}</MenuTitle>
      {contents.map((content) => {
        return _.some(content.items) ? (
          <SectionMenu
            content={content}
            handleSectionClick={handleSectionClick}
            hasDropdownChevron={hasDropdownChevron}
            isExpanded={itemsExpanded.has(content)}
            subItemSelected={Boolean(
              content.items?.some((i) => i === itemSelected)
            )}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            onSelect={onSelect}
            key={`sectionMenu-${menuTitle}-${content.title}`}
          />
        ) : null;
      })}
      <OtherContent>{children}</OtherContent>
    </ContentsWrapper>
  );
};

export default ContentsMenu;
