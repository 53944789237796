import styled from 'styled-components';
import InlineSVG from 'react-inlinesvg';

export const ComponentWrapper = styled.div`
  width: 100%;
`;

export const SelectInputBox = styled.input`
  height: 34px;
  width: 100%;
  border: none;
  box-sizing: border-box; // Prevent padding effecting 100% width
  padding: 0 8px;
  background-color: ${({ theme }): string => theme.colors.white};
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black80Alpha};
  ::placeholder {
    color: var(--black-30);
    letter-spacing: 0.2px;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const SelectBox = styled.div<{
  isSearchable?: boolean;
  itemIsSelected?: boolean;
  menuIsOpen: boolean;
}>`
  height: 34px;
  font-size: 14px;
  padding-left: ${({ isSearchable }) => (isSearchable ? '0' : '8px')};
  display: flex;
  position: relative;
  cursor: pointer;
  background: white;
  border-radius: 2px;
  border: solid 1px
    ${({ menuIsOpen, theme }): string =>
      menuIsOpen ? theme.colors.brandBlue : theme.colors.black10Alpha};
  color: ${({ itemIsSelected, theme }): string =>
    itemIsSelected ? theme.colors.black80Alpha : theme.colors.black30Alpha};
  &:hover {
    border: solid 1px
      ${({ menuIsOpen, theme }): string =>
        menuIsOpen ? theme.colors.brandBlue : theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const SelectedLabel = styled.div`
  padding-top: 9px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
`;

export const DropdownWrapper = styled.div<{ menuIsOpen: boolean }>`
  width: fit-content;
  position: absolute;
  cursor: pointer;
  left: calc(100% - 23px);
  top: 8px;
  background: transparent;
  border: none;
  ${({ menuIsOpen }): string =>
    menuIsOpen ? 'transform: rotate(180deg); top: 10px;' : ''};
`;

export const DropdownIcon = styled(InlineSVG)`
  width: 12px;
  path {
    fill: ${({ theme }): string => theme.colors.black70Alpha};
  }
`;

export const MenuOuterWrapper = styled.div<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: relative;
  width: 100%;
`;

export const MenuWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background: white;
  max-height: 200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  width: inherit;
  z-index: 999;
  ${({ isOpen }): string => (!isOpen ? 'display: none' : 'display: block')};
  margin-top: 3px;
  border-radius: 4px;
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  &:focus {
    outline: none;
  }
  &:first-child {
    padding-top: 4px;
  }
  &:last-child {
    padding-bottom: 4px;
  }
  .loading-wrapper {
    padding: 10px;
  }
`;

export const MenuItem = styled.div<{
  isSelected?: boolean;
  isHighlighted?: boolean;
}>`
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? '500' : '400')};
  color: ${({ isSelected, isHighlighted }): string => {
    if ((isHighlighted && isSelected) || isHighlighted) {
      return 'var(--brand-blue)';
    }

    return 'var(--black-90)';
  }};
  cursor: pointer;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 16px;
  background-color: ${({ isHighlighted, isSelected }): string => {
    if (isSelected && isHighlighted) {
      return 'var(--brand-blue-10)';
    }
    if (isSelected) {
      return 'var(--black-10)';
    }
    return isHighlighted && !isSelected ? 'var(--brand-blue-10)' : 'none';
  }};
  &:hover {
    box-shadow: none;
    color: ${({ theme }): string => theme.colors.brandBlue};
    background-color: ${({ theme }): string => theme.colors.brandBlue05Alpha};
  }
`;
