import React from 'react';
import type { RenderLeafProps } from 'slate-react';

type CustomRenderLeafAttributes = {
  'data-slate-leaf': true;
  style: Record<string, string | number[]>;
};
interface CustomRenderLeafProps extends RenderLeafProps {
  attributes: CustomRenderLeafAttributes;
}

const Leaf = ({
  attributes,
  children,
  leaf,
}: CustomRenderLeafProps): JSX.Element => {
  const newAttributes = attributes;
  let newChildren = children;

  if (leaf.textColor) {
    newAttributes.style = {
      ...attributes.style,
      color: leaf.textColor,
    };
  }

  if (leaf.highlightColor) {
    newAttributes.style = {
      ...attributes.style,
      backgroundColor: leaf.highlightColor,
    };
  }

  if (leaf.bold) {
    newChildren = <strong>{newChildren}</strong>;
  }

  if (leaf.italic) {
    newChildren = <em>{newChildren}</em>;
  }

  if (leaf.underline) {
    newChildren = <u>{newChildren}</u>;
  }

  return <span {...newAttributes}>{newChildren}</span>;
};

export default Leaf;
