import React, { memo, useEffect, useState } from 'react';
import type { Descendant, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import RedoIcon from '../../assets/RichTeIcons/icon-redo.svg';
import {
  HistoryButtonIcon,
  ToolButton,
  ToolButtonWrapper,
} from './ToolBarStyles';
import ToolTip from './ToolTip';

export default memo(
  ({
    editor,
    value,
  }: {
    editor: Editor & { redo(): void };
    value: Node[] | Descendant[];
  }) => {
    const [isVisibleTooltip, setTooltipIsVisible] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const handleRedo = (): void => {
      try {
        editor.redo();
        ReactEditor.focus(editor);
      } catch (e) {
        console.error(e);
      }
    };

    useEffect(() => {
      if (editor.history.redos.length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }, [value, editor.history.redos.length]);

    return (
      <ToolButtonWrapper>
        <ToolTip text="redo" isVisible={isVisibleTooltip}>
          <ToolButton
            onClick={handleRedo}
            data-testid="RedoButton"
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
            disabled={disabled}
          >
            <HistoryButtonIcon
              src={RedoIcon}
              alt="block button"
              disabled={disabled}
            />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
    );
  }
);
