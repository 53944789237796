import React, { useRef } from 'react';
import { ChapterButtonStyle } from './ContentsMenuStyles';
import type { ContentMenuItem, ContentSection } from './SectionMenu';

export type ChapterButtonProps = {
  content: ContentSection;
  item: ContentMenuItem;
  onClick: () => void;
  isActive: boolean;
};

const ChapterButton = ({
  content,
  item,
  onClick,
  isActive,
}: ChapterButtonProps): JSX.Element => {
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <ChapterButtonStyle
      isActive={isActive}
      onClick={() => {
        onClick();
        if (ref && ref.current) {
          ref.current.blur();
        }
      }}
      key={`${content.title}-chapterbutton-${item.title}`}
      data-testid={`ChapterButton-${item.title}`}
      ref={ref}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
    >
      {item.title}
    </ChapterButtonStyle>
  );
};

export default ChapterButton;
